/* App */

/* .app-container {
} */

/* Nav */

.nav-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 0 48px;
  font-size: 1.2em;
}

.nav-logo {
  margin: 12px auto 0 0;
}

.nav-logo img {
  width: 260px;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.nav-links a {
  color: #404756;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1em;
}

.nav-links a::after {
  display: block;
  content: '';
  width: 0;
  transition: all .4s ease;
}

.nav-links a:hover::after {
  content: '';
  position: absolute;
  display: block;
  z-index: 2;
  height: 3px;
  width: 100%;
  color: #2da343;
  background-color: #2da343;
  transition: all .4s ease;
}

/* Home */

.home-container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
}

.home-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  max-width: 650px;
  font-size: .95em;
  padding: 24px 48px 0 48px;
  box-sizing: border-box;
  text-align: justify;
}

.home-left h2 {
  margin: 0;
}

.home-right {
  width: 50%;
  margin-left: auto;
}

.home-right-imgFade {
  position: relative;
  width: 60%;
  height: 102%;
  background-image: linear-gradient(to right, white, rgba(0, 0, 0, .0));
  z-index: 1;
  top: -102%
}

.home-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-contact-btn {
  display: block;
  background-color: #2da343;
  margin: 0 auto;
  padding: 8px 24px;
  text-decoration: none;
  text-align: center;
  color: rgb(243, 243, 243);
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  font-size: 1.3em;
  border-radius: 8px;
}

.home-contact-btn:hover {
  background-color: #5caf6c;
}

/* Certifications */

.certifications-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px 0;
  padding: 0;
  width: 100%;
  background-color: #ebedef;
  z-index: 2;
}

.certifications-container h1 {
  margin: 40px 0;
  text-align: center;
}

.certifications-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 40px 0;
  height: 100px;
}

/* Services */

.services-container {
  margin: 40px 48px;
  padding: 40px 84px;
  box-sizing: border-box;
  background-color: #d0d7d0;
}

.services-container h1 {
  margin: 0;
}

.services-summary-p {
  font-size: 1.5em;
}

.services-subsection-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.services-subsection {
  margin: 0 0 0 48px;
}

.services-subsection h3 {
  font-size: 1.1em;
  font-weight: 300;
  margin: 8px;
}

.services-subsection h4 {
  font-size: 1em;
  font-weight: 300;
  margin: 8px 0 8px 16px;
}

.services-subsection-break {
  border-bottom: 2px solid rgb(59, 59, 59);
}

/* Contact */

.contact-container {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  padding: 0 0 40px 0;
  width: 100%;
  background-color: #ebedef;
}

.contact-container h1 {
  text-align: center;
}

.contact-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 24px 0 84px;
}

.contact-mail-h3, .contact-phone-h3 {
  position: absolute;
  transform: translate(-40%, 200%);
  margin: 0;
  text-align: center;
}

.contact-location-h3 {
  position: absolute;
  transform: translate(-40%, 100%);
  margin: 0;
  text-align: center;
}

.contact-icon-badge {
  display: flex;
  flex-direction: column;
}

.contact-icon-badge svg {
  color: #2da343;
  width: 40px;
  height: 40px;
}

.contact-form {
  width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  padding-bottom: 40px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.contact-form input, textarea {
  background-color: #f9f9fa;
  margin-bottom: 16px;
  line-height: 23px;
  box-shadow: 0px 2px 2px 1px #e1e1e1;
  border-radius: 4px;
  border: none;
}

.contact-form-btn {
  background-color: #2da343;
  color: #f0f0f0;
  border-radius: 3px;
  border: none;
  height: 30px;
}

.contact-form button:hover {
  background-color: #58bd6a;
}

.contact-form-success {
  background-color: rgb(102, 102, 238);
}

.contact-form-error {
  background-color: rgb(231, 146, 146);
}
